import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import Img from "gatsby-image"

import portrait from "../images/portrait.jpg"

const About = ({ data }) => {
  const image = data.allFile.edges[0].node.childImageSharp.fluid
  return (
    <Layout>
      {console.log("About -> data", data)}
      {/* {console.log("About -> data", image)} */}
      <div
        className="content-wrapper"
        style={{
          background: `#be4142`,
          minHeight: `100%`,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <SEO title="About" />
        <h1
          style={{
            maxWidth: `960px`,
            color: `white`,
            margin: `0`,
            marginBottom: "8px",
          }}
        >
          About me
        </h1>
        <div className="about-me">
          <div className="image">
            <Img fluid={image} width="100%" />
          </div>
          <div className="about-me-content">
            <p
              style={{
                marginBottom: `12px`,
                color: `white`,
              }}
            >
              I’m a digital marketer, specialised in technical SEO. I grew up
              with my dad as a marketing director for Coca Cola and as a
              consequence all my life I’ve had an interest in marketing,
              advertising in particular – I mean, is anyone out there better at
              it than Coke? However, having an inborn flair for numbers, I
              studied Sciences and Engineering in uni. My goal was always to be
              capable to create data-based{" "}
              <strong>original marketing campaigns</strong>, with{" "}
              <strong>clear KPIs</strong>.
            </p>
            <p
              style={{
                marginBottom: `12px`,
                color: `white`,
              }}
            >
              Beside my analytics side, I still love to write, read, and come up
              with cool ideas to promote brands! I have been blogging for many
              years about various topics, and had a few work experiences as a
              copywriter, editor for a Japanese girls magazine or creating PR
              stunts for Pizza Hut, Coke and Harley Davidson. You can find more
              about my background and experience{" "}
              <Link
                style={{ textDecoration: `underline`, fontWeight: `bold` }}
                to="/experience"
              >
                here
              </Link>
              .
            </p>
            <p
              style={{
                color: `white`,
              }}
            >
              In my free time, I like a good challenge to keep me on my toes!
              That’s either picking up a language like Japanese or Javascript or
              baking a cake (honestly, it’s hard). I also love to pour myself a
              G&T and chill after a long day or take some time off for a good
              travel experience. Now, do you think we could work together? I’m
              sure we could and I’d love to meet you so contact me{" "}
              <Link
                style={{ textDecoration: `underline`, fontWeight: `bold` }}
                href="/contact"
              >
                here
              </Link>{" "}
              or at apostol.almalavinia@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/portrait/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1000, quality: 85, traceSVG: { color: "#2B2B2F" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
export default About
